import { Box } from '@theme-ui/components';
import { useInView } from 'react-intersection-observer';

const Animation: React.FC<{
  src: string;
  width?: string | (string | number)[];
  height?: string | (string | number)[];
  forceHeight?: boolean;
  forceWidth?: boolean;
}> = ({ src, width = '100%', height = '100%', forceHeight = false, forceWidth = false }) => {
  const { ref: videoRef, inView: videoInView } = useInView();
  const center = forceHeight ? { display: 'flex', justifyContent: 'center' } : forceWidth ? {} : {};
  return (
    <Box
      sx={{
        width,
        height,
        overflow: 'hidden',
        ...center,
        '& > video': {
          objectFit: 'cover',
          width,
          height,
          maxHeight: forceHeight ? '100%' : 'none',
          maxWidth: forceWidth ? '100%' : 'none',
        },
      }}
    >
      <video ref={videoRef} controls={false} autoPlay loop muted playsInline preload="auto">
        <source src={`/media/${src}.webm`} type="video/webm" />
        <source src={`/media/${src}.mp4`} type="video/mp4" />
      </video>
    </Box>
  );
};

export default Animation;
