import Floormap from 'components/floormap';
import HotSpot from 'components/hotspot';

const OverlayGroundfloor: React.FC = () => {
  return (
    <Floormap src="media/allianz_17_stock">
      <HotSpot pos={[79.5, 39]} text="Oberalp" />
      <HotSpot pos={[78, 55]} text="Albis" />
      <HotSpot pos={[70.5, 70]} text="Julier" />
      <HotSpot pos={[67.5, 31]} text="Maloja" />
      <HotSpot pos={[55, 26]} text="Susten" />
      <HotSpot
        pos={[47, 37]}
        text="Tee oder Kaffee gefällig? Auf dem Rastplatz kannst du dich während der Pause entspannen."
      />
      <HotSpot
        pos={[58, 43.5]}
        text="Wo oder was? Ein Blick auf den Bildschirm genügt und du erhältst alle Informationen zu deinem Kurs."
      />
      <HotSpot pos={[35.5, 58.5]} text="Brünig" />
      <HotSpot pos={[26, 54]} text="Furka" />
      <HotSpot
        pos={[28, 42]}
        text="Hast du nach dem Kurstag einen Kundentermin geplant? Hier kannst du kurzfristig Unterlagen drucken."
      />
    </Floormap>
  );
};

export default OverlayGroundfloor;
