import { motion } from 'framer-motion';
import { useState } from 'react';
import { colors } from 'styles/themeColors';
import { Box, Image, Text } from 'theme-ui';

const HotSpot: React.FC<{ pos: [number, number]; text: string }> = ({ pos, text }) => {
  const [hovering, setHover] = useState(false);
  const tooltipVariants = {
    hidden: {
      opacity: 0,
    },
    open: {
      y: '-100%',
      opacity: 1,
    },
    closed: {
      y: '-80%',
      opacity: 0,
    },
  };
  return (
    <>
      <Box
        css={{
          position: 'absolute',
          top: `${pos[0]}%`,
          left: `${pos[1]}%`,
          zIndex: hovering ? 9999999 : 9999,
          ':hover': {
            cursor: 'pointer',
          },
        }}
      >
        <motion.div
          style={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          initial={false}
          onTap={() => setHover(!hovering)}
        >
          <Box
            css={{ transform: 'translateX(-50%)' }}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
            onContextMenu={(e) => e.preventDefault()}
          >
            <Image
              sx={{ width: [28, 32, 36] }}
              css={{ display: 'block' }}
              src={hovering ? '/images/hotspot-button-active.svg' : '/images/hotspot-button.svg'}
            />
          </Box>
        </motion.div>
      </Box>
      <motion.div
        css={{
          position: 'absolute',
          top: `${pos[0]}%`,
          left: `${pos[1]}%`,
          zIndex: hovering ? 99999999 : 99999,
          cursor: 'default',
          pointerEvents: 'none',
          ':hover': {
            cursor: 'default',
            pointerEvents: 'none',
          },
        }}
        variants={tooltipVariants}
        animate={hovering ? 'open' : 'closed'}
        initial="hidden"
        onMouseEnter={(e) => e.preventDefault()}
        onMouseLeave={(e) => e.preventDefault()}
        onTouchStart={(e) => e.preventDefault()}
        onTouchEnd={(e) => e.preventDefault()}
      >
        <Box
          backgroundColor={colors.primary}
          color={colors.background}
          sx={{ px: 3, py: 2 }}
          css={{
            maxWidth: '230px',
            height: 'auto',
            borderRadius: '8px',
            transform: 'translate(-50%, -6px)',
          }}
        >
          <Text css={{ textAlign: 'center', fontSize: '16px', lineHeight: '110%' }}>{text}</Text>
          <Box
            backgroundColor={colors.primary}
            color={colors.text}
            css={{
              position: 'absolute',
              left: '50%',
              bottom: '-6px',
              transform: 'translateX(-50%) rotate(45deg)',
              width: '16px',
              height: '16px',
              borderRadius: '4px',
            }}
          />
        </Box>
      </motion.div>
    </>
  );
};
export default HotSpot;
