/* eslint-disable jsx-a11y/media-has-caption */
import Animation from 'components/animation';
import LayoutBox from 'components/layoutBox';
import { spacing } from 'styles/spacing';
import { Box, Heading, Text } from 'theme-ui';

const ScrollIntro: React.FC = () => {
  return (
    <>
      <LayoutBox>
        <Box sx={{ pb: spacing.wide }}>
          <Heading as="h1" variant="h1">
            Herzlich Willkommen im Schulungszentrum der Sales Academy
          </Heading>
        </Box>
        <Box
          css={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <Box sx={{ py: spacing.narrow }}>
            <Animation width={[300, 540, 720]} forceWidth src="ani_tower" />
          </Box>
          <Box sx={{ textAlign: 'center', py: spacing.narrow, maxWidth: [300, 540, 720] }}>
            <Text p={0} variant="normal">
              Demnächst startet deine Ausbildung. Erfahre jetzt schon, was dich am Kurstag im
              Schulungszentrum der Allianz Suisse erwartet.
              <br />
              <br />
              Viel Spass!
            </Text>
          </Box>
        </Box>
      </LayoutBox>
    </>
  );
};

export default ScrollIntro;
