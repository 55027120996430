import { useState } from 'react';
import { Box } from 'theme-ui';
import useResizeObserver from 'use-resize-observer';
import { motion } from 'framer-motion';

const Floormap: React.FC<{ children: React.ReactNode; src: string }> = ({ children, src }) => {
  const [width, setWidth] = useState(1);
  const [height, setHeight] = useState(1);
  const [ended, setEnded] = useState(false);
  const { ref } = useResizeObserver<HTMLImageElement>({
    onResize: ({ width, height }) => {
      setWidth(width || 1);
      setHeight(height || 1);
    },
  });
  const variants = {
    open: { opacity: 1 },
    closed: { opacity: 0 },
  };
  return (
    <Box
      sx={{
        width: ['250vw', '250vw', '100%'],
        height: '100%',
        // marginLeft: ['-40%', '-40%', '0'],
      }}
      css={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
      }}
    >
      <Box
        sx={{
          maxWidth: ['250vw', '250vw', '75vw'],
        }}
        css={{
          position: 'relative',
          zIndex: 1000,
          // maxHeight: '80vh',
        }}
      >
        <Box ref={ref}>
          <video
            css={{
              display: 'block',
              width: 'auto',
              height: 'auto',
              maxHeight: '100%',
              maxWidth: '100%',
              objectFit: 'contain',
              overflow: 'hidden',
            }}
            controls={false}
            autoPlay
            muted
            playsInline
            preload="auto"
            onEnded={() => setEnded(true)}
          >
            <source src={`${src}.webm`} type="video/webm" />
            <source src={`${src}.mp4`} type="video/mp4" />
          </video>
        </Box>

        {ended && (
          <Box css={{ position: 'absolute', top: 0, left: 0, width, height, zIndex: 1001 }}>
            <motion.div
              css={{ width: '100%', height: '100%' }}
              animate={ended ? 'open' : 'closed'}
              variants={variants}
            >
              {children}
            </motion.div>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default Floormap;
