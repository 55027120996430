import { Box } from 'theme-ui';

const BoxTilesThreeCols: React.FC<{
  cols: React.ReactNode[];
  bg?: string;
}> = ({ cols, bg = 'transparent' }) => {
  return (
    <Box
      sx={{
        display: ['block', 'grid'],
        gridTemplateColumns: 'repeat(3, 1fr)',
      }}
      css={{
        backgroundColor: bg,
      }}
    >
      {cols.map((col, idx) => (
        <Box key={idx}>{col}</Box>
      ))}
    </Box>
  );
};

export default BoxTilesThreeCols;
