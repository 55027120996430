/* eslint-disable jsx-a11y/media-has-caption */
import BoxTilesTwoCols from 'components/boxTilesTwoCols';
import BoxTileText from 'components/boxTileText';
import LayoutBox from 'components/layoutBox';
import LinkArrow from 'components/linkArrow';
import { useState } from 'react';
import { colors } from 'styles/themeColors';
import { Box, Embed, Heading } from 'theme-ui';
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import OverlayGroundfloor from 'components/overlays/02-groundfloor';
import { getVimeoUrl } from 'helpers/vimeoUrl';
import useResizeObserver from 'use-resize-observer';
import { spacing } from 'styles/spacing';
import { isIosSafari } from 'helpers/isIosSafari';

const ScrollGroundfloor: React.FC = () => {
  const [open, setOpen] = useState(false);

  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);

  const [width, setWidth] = useState(1);
  const [height, setHeight] = useState(1);
  const { ref } = useResizeObserver<HTMLImageElement>({
    onResize: ({ width, height }) => {
      setWidth(width || 1);
      setHeight(height || 1);
    },
  });

  return (
    <>
      <Modal
        open={open}
        onClose={onCloseModal}
        blockScroll={!isIosSafari()}
        center
        classNames={{
          overlay: 'customOverlay',
          modal: 'customModal',
        }}
      >
        <OverlayGroundfloor />
      </Modal>
      <LayoutBox bgColor={colors.project.blueBackground.light}>
        <Box sx={{ pb: spacing.wide }}>
          <Heading as="h2" variant="h2">
            Action!
          </Heading>
        </Box>
        <Box sx={{ pb: spacing.wide }}>
          <Embed src={getVimeoUrl('513762506')} />
        </Box>
        <BoxTilesTwoCols
          bg={colors.project.blueBox.primary}
          left
          cols={[
            <>
              <BoxTileText>
                <Heading as="h3" variant="h3">
                  Badge am Empfang abholen
                </Heading>
              </BoxTileText>
            </>,
            <>
              <Box ref={ref}>
                <Embed src={getVimeoUrl('513762453')} />
              </Box>
            </>,
          ]}
        />
        <BoxTilesTwoCols
          bg={colors.project.greenBox.primary}
          cols={[
            <>
              <Box
                css={{
                  width,
                  height,
                  background: 'url(/images/allianz_still-3.jpg) center center',
                  backgroundSize: 'cover',
                }}
              />
            </>,
            <>
              <BoxTileText>
                <Heading as="h3" variant="h3">
                  Viel Raum für gute Ideen
                </Heading>
                <LinkArrow actionCallback={onOpenModal}>Alles Wichtige im Erdgeschoss</LinkArrow>
              </BoxTileText>
            </>,
          ]}
        />
        <Box pb={spacing.wide} />
        <BoxTilesTwoCols
          bg={colors.project.beigeBox.primary}
          left
          cols={[
            <>
              <BoxTileText>
                <Heading as="h3" variant="h3">
                  Wähle den 17. Stock und merk dir den Buchstaben.
                </Heading>
              </BoxTileText>
            </>,
            <>
              <Box
                css={{
                  width,
                  height,
                  background: 'url(/images/allianz_still4.jpg) center center',
                  backgroundSize: 'cover',
                }}
              />
            </>,
          ]}
        />
      </LayoutBox>
    </>
  );
};

export default ScrollGroundfloor;
