import { spacing } from 'styles/spacing';
import { Box } from 'theme-ui';

const LayoutBox: React.FC<{
  children: React.ReactNode;
  bgColor?: string;
  color?: string;
  p?: number[] | number;
  noPadding?: boolean;
}> = ({ children, bgColor, color, p, noPadding }) => {
  return (
    <Box
      color={color || 'default'}
      backgroundColor={bgColor || 'transparent'}
      css={{ width: '100%' }}
      sx={noPadding ? {} : { py: p || spacing.wide, px: [3, 3, 4, 0] }}
    >
      <Box
        margin="auto"
        sx={{
          width: ['100%', '100%', '100%', 960, 1140],
        }}
      >
        {children}
      </Box>
    </Box>
  );
};

export default LayoutBox;
