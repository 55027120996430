import Floormap from 'components/floormap';
import HotSpot from 'components/hotspot';

const OverlayGroundfloor: React.FC = () => {
  return (
    <Floormap src="/media/allianz_eg">
      <HotSpot
        pos={[58, 14.5]}
        text="Eingang Richtiplatz. Vom Bahnhof gelangst du direkt hierher."
      />
      <HotSpot
        pos={[47, 33]}
        text="Badges, Infos und ein freundliches Lächeln holst du dir beim Empfang ab."
      />
      <HotSpot pos={[28.5, 51.5]} text="Eingang Glattzentrum. Shopping, yes please!" />
      <HotSpot pos={[54.5, 82]} text="Hol dir eine Erfrischung an der Tower Bar." />
      <HotSpot pos={[59, 34]} text="Der Lift fährt dich hoch hinaus ins Schulungszentrum." />
    </Floormap>
  );
};

export default OverlayGroundfloor;
