import { Box } from 'theme-ui';

const BoxTilesTwoCols: React.FC<{
  cols: React.ReactNode[];
  left?: boolean;
  bg?: string;
}> = ({ cols, left = false, bg = 'transparent' }) => {
  const leftCol = {
    '> div:first-of-type': {
      width: ['100%', '100%', '33.333333%'],
    },
    '> div:last-of-type': { width: ['100%', '100%', '66.666666%'] },
  };

  const rightCol = {
    '> div:first-of-type': {
      width: ['100%', '100%', '66.666666%'],
    },
    '> div:last-of-type': { width: ['100%', '100%', '33.333333%'] },
  };

  const col = left ? leftCol : rightCol;
  return (
    <Box
      sx={{
        flexDirection: [
          left ? 'column-reverse' : 'column',
          left ? 'column-reverse' : 'column',
          'row',
        ],
        ...col,
      }}
      css={{
        display: 'flex',
        alignItems: 'stretch',
        backgroundColor: bg,
      }}
    >
      {cols.map((col, idx) => (
        <Box key={`a-${idx}`}>{col}</Box>
      ))}
    </Box>
  );
};

export default BoxTilesTwoCols;
