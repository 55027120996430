import Floormap from 'components/floormap';
import HotSpot from 'components/hotspot';

const OverlayCantina: React.FC = () => {
  return (
    <Floormap src="media/allianz_5stock">
      <HotSpot
        pos={[82.5, 63]}
        text="Über diese Passerellen gelangst du zum Bistro, zu zusätzlichen Pausenzonen und zum Flachbau"
      />
      <HotSpot
        pos={[51, 32]}
        text="Bei uns wird bargeldlos bezahlt. Deinen Badge lädst du an der Ladestation mit Maestro- oder Kreditkarte auf."
      />
      <HotSpot
        pos={[41, 39.5]}
        text="Such dir beim Vorbeigehen etwas Leckeres vom Bildschirm aus."
      />
      <HotSpot
        pos={[48, 68]}
        text="Dein Essen an der frischen Luft geniessen? Kein Problem, die Terrasse lädt zum Verweilen ein."
      />
      <HotSpot pos={[72, 20]} text="Nimm hier Platz und geniesse dein Essen. En Guete!" />
    </Floormap>
  );
};

export default OverlayCantina;
