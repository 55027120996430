/* eslint-disable jsx-a11y/media-has-caption */
import BoxTilesThreeCols from 'components/boxTilesThreeCols';
import BoxTileText from 'components/boxTileText';
import LayoutBox from 'components/layoutBox';
import LinkArrow from 'components/linkArrow';
import { colors } from 'styles/themeColors';
import { Box, Embed, Heading } from 'theme-ui';
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import { useState } from 'react';
import OverlaySeventeenth from 'components/overlays/03-seventeenth';
import { getVimeoUrl } from 'helpers/vimeoUrl';
import useResizeObserver from 'use-resize-observer';
import Animation from 'components/animation';
import { spacing } from 'styles/spacing';
import BoxTilesTwoCols from 'components/boxTilesTwoCols';
import { isIosSafari } from 'helpers/isIosSafari';

const ScrollSeventeenth: React.FC = () => {
  const [open, setOpen] = useState(false);

  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);

  const [width, setWidth] = useState(1);
  const [height, setHeight] = useState(1);
  const { ref } = useResizeObserver<HTMLImageElement>({
    onResize: ({ width, height }) => {
      setWidth((width || 1) / 3);
      setHeight((width || 1) / 3);
    },
  });

  const content = [
    <>
      <Box
        sx={{ width: ['100%', '100%', width], height: [height * 1.5, height * 1.5, height] }}
        css={{
          background: 'url(/images/allianz_still-5.jpg) center center',
          backgroundSize: 'cover',
        }}
      />
    </>,
    <>
      <BoxTileText>
        <Heading as="h3" variant="h3">
          Wo ist der Brünig?
        </Heading>
        <LinkArrow actionCallback={onOpenModal}>Alle Schulungsräume</LinkArrow>
      </BoxTileText>
    </>,
    <>
      <Box
        sx={{ width: ['100%', '100%', width], height: [height * 1.5, height * 1.5, height] }}
        css={{
          background: 'url(/images/allianz_still-6.jpg) center center',
          backgroundSize: 'cover',
        }}
      />
    </>,

    <>
      <BoxTileText>
        <Heading as="h3" variant="h3">
          Tee und Kaffee gibt’s in den Pausenzonen
        </Heading>
      </BoxTileText>
    </>,
    <>
      <Animation
        width={['100%', '100%', width]}
        height={[height * 1.5, height * 1.5, height]}
        src="ani_kaffee"
      />
    </>,
    <>
      <BoxTileText>
        <Heading as="h3" variant="h3">
          Geheimtipp: Dachterrasse!
        </Heading>
      </BoxTileText>
    </>,
  ];

  return (
    <>
      <Modal
        open={open}
        onClose={onCloseModal}
        blockScroll={!isIosSafari()}
        center
        classNames={{
          overlay: 'customOverlay',
          modal: 'customModal',
        }}
      >
        <OverlaySeventeenth />
      </Modal>
      <LayoutBox bgColor={colors.project.blueBackground.dark}>
        <Box ref={ref} color={colors.background} sx={{ pb: spacing.wide }}>
          <Heading as="h2" variant="h2">
            Mit Aussicht zur kompetenten Kundenberaterin oder zum kompetenten Kundenberater
          </Heading>
        </Box>
        <Box sx={{ pb: spacing.wide }}>
          <Embed src={getVimeoUrl('513762639')} />
        </Box>
        <Box sx={{ display: ['none', 'none', 'block'] }}>
          <BoxTilesThreeCols bg={colors.project.blueBox.primary} cols={content.slice(0, 3)} />
          <BoxTilesThreeCols bg={colors.project.beigeBox.primary} cols={content.slice(3)} />
        </Box>
        <Box sx={{ display: ['block', 'block', 'none'] }}>
          <BoxTilesTwoCols
            bg={colors.project.beigeBox.primary}
            left
            cols={[content[1], content[0]]}
          />
          <BoxTilesTwoCols
            bg={colors.project.beigeBox.primary}
            left
            cols={[content[5], content[2]]}
          />
          <BoxTilesTwoCols
            bg={colors.project.beigeBox.primary}
            left
            cols={[content[3], content[4]]}
          />
        </Box>
      </LayoutBox>
    </>
  );
};

export default ScrollSeventeenth;
