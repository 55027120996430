/* eslint-disable jsx-a11y/media-has-caption */
import BoxTilesTwoCols from 'components/boxTilesTwoCols';
import BoxTileText from 'components/boxTileText';
import LayoutBox from 'components/layoutBox';
import LinkArrow from 'components/linkArrow';
import { useState } from 'react';
import { Box, Text, Embed, Heading } from 'theme-ui';
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import OverlayCantina from 'components/overlays/04-cantina';
import { getVimeoUrl } from 'helpers/vimeoUrl';
import Animation from 'components/animation';
import { spacing } from 'styles/spacing';
import { isIosSafari } from 'helpers/isIosSafari';
import { colors } from 'styles/themeColors';

const ScrollCantina: React.FC = () => {
  const [open, setOpen] = useState(false);

  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);
  return (
    <>
      <Modal
        open={open}
        onClose={onCloseModal}
        blockScroll={!isIosSafari()}
        center
        classNames={{
          overlay: 'customOverlay',
          modal: 'customModal',
        }}
      >
        <OverlayCantina />
      </Modal>
      <LayoutBox>
        <Box sx={{ pb: spacing.wide }}>
          <Heading as="h2" variant="h2">
            Personalrestaurant im 5. Stock
          </Heading>
        </Box>
        <Box sx={{ pb: spacing.wide }}>
          <Box
            css={{
              display: 'grid',
              gridGap: 25,
              gridTemplateColumns: `repeat(auto-fit, minmax(48%, 1fr))`,
            }}
          >
            <Animation width="auto" height={[320, 320, 400]} forceHeight src="ani_card" />
            <Box sx={{ height: '100%', textAlign: ['center', 'center', 'left'] }}>
              <Box
                css={{
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                }}
              >
                <Heading as="h3" variant="h3" pb={[3, 3, 4]}>
                  Badge aufladen
                </Heading>
                <Text variant="normal">
                  Bevor du im Personalrestaurant dein wohlverdientes Mittagsmenü am Buffet aussuchen
                  kannst, musst du den Badge an der Ladestation aufladen.
                </Text>
              </Box>
            </Box>
          </Box>
        </Box>
        <BoxTilesTwoCols
          bg={colors.project.beigeBox.primary}
          cols={[
            <>
              <Box>
                <Embed src={getVimeoUrl('513762426')} />
              </Box>
            </>,
            <>
              <BoxTileText>
                <Heading as="h3" variant="h3">
                  Lernen macht hungrig
                </Heading>
                <LinkArrow actionCallback={onOpenModal}>Alles Wichtige im 5. Stock</LinkArrow>
              </BoxTileText>
            </>,
          ]}
        />
      </LayoutBox>
    </>
  );
};

export default ScrollCantina;
