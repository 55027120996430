import LayoutBox from 'components/layoutBox';
import { spacing } from 'styles/spacing';
import { colors } from 'styles/themeColors';
import { Box, Heading, Link, Text } from 'theme-ui';

const ScrollFooter: React.FC = () => {
  return (
    <>
      <LayoutBox
        p={spacing.narrow}
        bgColor={colors.project.blueBackground.dark}
        color={colors.background}
      >
        <Heading pb={2} as="h2" variant="h3">
          Hast du Fragen?
        </Heading>
        <Text sx={{ fontSize: [2, 2, 3, 4] }}>
          Gerne beantworten wir sie dir per Email oder Telefon unter
{' '}
          <Link href="mailto:training@allianz.ch" variant="negative">
            training@allianz.ch
          </Link>
{' '}
          oder
{' '}
          <Link href="tel:0041583587180" variant="negative">
            058 358 71 80
          </Link>
          .
        </Text>
      </LayoutBox>
      <LayoutBox p={spacing.narrow} bgColor={colors.text} color={colors.background}>
        <Box css={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end' }}>
          <Box>
            <Text variant="small">
              <strong>Datenschutz:</strong>
{' '}
Diese Webseite speichert keinerlei persönliche Daten.
<br />
              <strong>Impressum:</strong>
{' '}
              <Link href="mailto:training@allianz.ch" variant="negative">
                training@allianz.ch
              </Link>
            </Text>
          </Box>
          <Box>
            <Text variant="small">© 2021 Allianz - Alle Rechte vorbehalten</Text>
          </Box>
        </Box>
      </LayoutBox>
    </>
  );
};

export default ScrollFooter;
