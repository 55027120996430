import { Box } from 'theme-ui';

const BoxTileText: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return (
    <Box sx={{ height: '100%', width: '100%' }}>
      <Box
        sx={{ p: [24, 28, 32, 48] }}
        css={{
          height: '100%',
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          '> h3': {
            margin: 0,
            padding: 0,
          },
        }}
      >
        {children}
      </Box>
    </Box>
  );
};

export default BoxTileText;
