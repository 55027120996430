/* eslint-disable jsx-a11y/media-has-caption */
import LayoutBox from 'components/layoutBox';
import LayoutContainer from 'components/layoutContainer';
import ScrollIntro from 'components/scrollparts/01-intro';
import ScrollGroundfloor from 'components/scrollparts/02-groundfloor';
import ScrollSeventeenth from 'components/scrollparts/03-seventeeth';
import ScrollCantina from 'components/scrollparts/04-cantina';
import ScrollFooter from 'components/scrollparts/05-footer';
import { NextPage } from 'next';
import { NextSeo } from 'next-seo';
import { Box, Button, Image } from 'theme-ui';
import { motion } from 'framer-motion';
import ScrollOutro from 'components/scrollparts/05-outro';
import { useInView } from 'react-intersection-observer';

const Index: NextPage = () => {
  const { ref, inView } = useInView();
  const topNavVariants = {
    open: { opacity: 1, y: 0 },
    closed: { opacity: 0, y: 80 },
  };

  const scrollToTop = () => {
    if (typeof window !== 'undefined') {
      window.scrollTo(0, 0);
    }
  };

  return (
    <>
      <NextSeo title="Sales Academy Schulungszentrum" />
      <LayoutContainer>
        <LayoutBox noPadding>
          <Box sx={{ px: [2, 3, 4], py: [2, 3, 4] }}>
            <Image
              ref={ref}
              sx={{ width: 'auto', height: [28, 28, 32, 'auto'] }}
              css={{ display: 'block' }}
              src="/images/logo.svg"
            />
          </Box>
        </LayoutBox>
        <Box
          sx={{
            background: 'url(/images/header.jpg)',
            backgroundSize: 'cover',
            width: ['100%'],
            height: [300, 300, 600],
          }}
        />
        <ScrollIntro />
        <ScrollGroundfloor />
        <ScrollSeventeenth />
        <ScrollCantina />
        <ScrollOutro />
        <ScrollFooter />
        <motion.div
          css={{ position: 'fixed', bottom: '16px', right: '32px' }}
          animate={!inView ? 'open' : 'closed'}
          variants={topNavVariants}
          initial="closed"
        >
          <Button variant="iconButton" onClick={scrollToTop}>
            <img src="/images/nav-top.svg" alt="An den Anfang zurück" />
          </Button>
        </motion.div>
      </LayoutContainer>
    </>
  );
};

export default Index;
