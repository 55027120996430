import { motion } from 'framer-motion';
import { useState } from 'react';
import { Text, Image, Box, Button } from 'theme-ui';

const LinkArrow: React.FC<{ children: React.ReactNode; actionCallback: () => void }> = ({
  children,
  actionCallback,
}) => {
  const [hovering, setIsHover] = useState(false);
  return (
    <Box sx={{ py: 2, mt: [4, 4, 0] }}>
      <Button variant="iconButton" type="button" onClick={actionCallback}>
        <Box sx={{ pr: 2 }} css={{ display: 'flex', alignItems: 'center', width: 23 }}>
          <motion.div
            variants={{
              hover: {
                x: 5,
              },
              init: {
                x: 0,
              },
            }}
            animate={hovering ? 'hover' : 'init'}
            initial="init"
          >
            <Image width="16px" css={{ display: 'block' }} src="/images/ic-24-arrow-right.svg" />
          </motion.div>
        </Box>
        <Box>
          <Text
            onMouseEnter={() => setIsHover(true)}
            onMouseLeave={() => setIsHover(false)}
            sx={{ fontSize: [14] }}
            css={{
              display: 'flex',
              alignItems: 'center',
              textAlign: 'center',
              lineHeight: '1.2',
              textTransform: 'uppercase',
              fontWeight: 600,
              letterSpacing: '0.8px',
            }}
          >
            {children}
          </Text>
        </Box>
      </Button>
    </Box>
  );
};

export default LinkArrow;
